<template>
  <v-card min-width="400" height="100%">
    <v-card-title class="text-h5 ap-orange ap-white--text">
      Entity Form Simulator
    </v-card-title>

    <v-card-text class="pt-5">
      <div>
        <v-select
          v-model="json.inputType"
          :items="entities"
          label="Entity"
          outlined
          dense
          shaped
          hide-details
        />

        <v-btn-toggle
          v-model="formType"
          class="d-flex"
          tile
          @change="onFormTypeChange"
        >
          <v-btn height="40" value="new" width="50%"> New </v-btn>
          <v-btn height="40" value="edit" width="50%"> Edit </v-btn>
        </v-btn-toggle>
      </div>

      <v-jsoneditor
        v-if="json.inputQuery"
        v-model="json.inputQuery"
        :plus="false"
        height="400px"
        :options="{
          mode: 'code',
          onChangeText: onFormChanged,
        }"
      />
    </v-card-text>

    <template v-if="formType">
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="ap-orange"
          :disabled="isEmptyForm"
          @click="onFormSave"
        >
          Save
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import VJsoneditor from 'v-jsoneditor'

export default {
  components: {
    VJsoneditor,
  },
  data() {
    return {
      formType: null,
      entities: ['contact'],
      json: {
        inputType: 'contact',
      },
    }
  },
  computed: {
    isEmptyForm() {
      return Object.keys(this.json?.inputQuery).length === 0
    },
  },
  methods: {
    onFormTypeChange() {
      if (this.formType === 'new') {
        this.json = {
          ...this.json,
          inputQuery: {},
        }
      } else {
        this.json = {
          ...this.json,
          inputQuery: {
            firstname: 'Jon',
          },
        }
      }

      window.postMessage(
        {
          type: 'ApFormChange',
          data: this.json,
        },
        '*'
      )
    },
    onFormChanged(jsonText) {
      try {
        JSON.parse(jsonText)

        window.postMessage(
          {
            type: 'ApFormChange',
            data: this.json,
          },
          '*'
        )
      } catch {
        // invalid json
      }
    },

    onFormSave() {
      window.postMessage(
        {
          type: 'ApFormSave',
          data: this.json,
        },
        '*'
      )
    },
  },
}
</script>
